import React, { useLayoutEffect } from "react";
import config from "../config";
import "../styles/landingpage.css";

function LandingPage(props) {
  useLayoutEffect(() => {
    console.log(window.location.replace(config.redirectPath || "https://consumer.iselfietest.com"));
  }, []);

  return (
    <div className="container">
      <div className="main landingpage">asdf</div>
    </div>
  );
}

export default LandingPage;

import React from 'react';
import '../styles/errorpage.css';

export default function ErrorPage(props) {
    return (
        <div className='container'>
            <div className='main errorpage'>
                <div className='wrapper'>
                    <p className='text-blue'>Ooops..so sorry</p>
                    <p>Page Not Found!</p>
                    <p className='error-code mt-5 mb-5'>404</p>
                    <button id='home' onClick={()=>props.history.push('/home')}>Home</button>
                </div>
            </div>
        </div>
    )
}

import React from "react";
import ReactDOM from "react-dom";
import GA4React from "ga-4-react";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
const ga4react = new GA4React("G-DG7YBYX1NK");

(async (_) => {
  await ga4react
    .initialize()
    .then()
    .catch((err) => console.error("Analytics Failure."))
    .finally(() => {
      ReactDOM.render(
        <React.StrictMode>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </React.StrictMode>,
        document.getElementById("root")
      );
    });
})();

import React from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import ErrorPage from './pages/ErrorPage';
import LandingPage from './pages/LandingPage';

function App() {
  return (
    <React.Fragment>
      <div className="app">
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route path='*' component={ErrorPage} />
        </Switch>
      </div>
    </React.Fragment>
  );
}

export default withRouter(App);
